import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Capability {
  name: string;
  id: string;
  capabilities?: Capability[];
}

export const useCapabilities = () => {
  return useQuery({
    queryKey: ['capabilities'],
    queryFn: () =>
      axios.get('/rest/admin/config/capabilities').then(({ data }) => {
        return data?.capabilities?.map((capability: Capability) => ({
          label: capability.name,
          value: capability.id,
        }));
      }),
    enabled: false,
  });
};
