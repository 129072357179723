import { ApartmentOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { Partner } from 'api/partnersApi';

import { useOrganizationSelector } from './useOrganizationSelector';
import { MainNavigationMenuItem } from '../MainNavigationMenuItem';

const Title = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
`;

const Title2 = styled(Title)`
  margin-bottom: 0.5rem;
`;

const Name = styled.div`
  font-size: 0.875rem !important;
  font-size: 1rem;
`;

const OrganizationIcon = styled(ApartmentOutlined)`
  color: var(--P24-Brand-Primary-brand-colors-Blue-Blue-5);
`;

const OrganizationIconCollapsed = styled(OrganizationIcon)`
  font-size: ${18 / 16}rem;
`;

export const OrganizationComponent = ({
  partners,
  onChange,
  currentPartnerId,
}: {
  partners: Partner[];
  onChange: (partnerId: string) => void;
  currentPartnerId?: string;
}) => {
  if (partners.length === 0) {
    return null;
  }

  if (partners.length === 1) {
    return (
      <MainNavigationMenuItem
        icon={<OrganizationIcon />}
        label={
          <div>
            <Title>
              <FormattedMessage id="Organization" />:
            </Title>
            <Name>{currentPartnerId}</Name>
          </div>
        }
      />
    );
  }

  return (
    <MainNavigationMenuItem
      collapsedView={<OrganizationIconCollapsed />}
      label={
        <div>
          <Title2>
            <FormattedMessage id="Select organization" />:
          </Title2>
          <Select showSearch style={{ width: '95%' }} value={currentPartnerId} onSelect={onChange}>
            {partners.map(partner => (
              <Select.Option key={partner.id} value={partner.id}>
                {partner.id}
              </Select.Option>
            ))}
          </Select>
        </div>
      }
    />
  );
};

export const Organization = () => {
  const data = useOrganizationSelector();

  return <OrganizationComponent {...data} />;
};
