import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { intersection } from 'lodash';

import { ScopedRoles } from 'api/permissionsApi';
import { LOGIN_ALLOWED_ROLES, ROLES } from 'constants/roles';

import { partnersAtoms } from '../partners/partners.atoms';

interface UserPermissionsState {
  myScopedRoles: ScopedRoles[];
  roles?: ROLES[];
  isLoaded: boolean;
}

const root = atom<UserPermissionsState>({
  myScopedRoles: [],
  isLoaded: false,
});

const myScopedRoles = focusAtom(root, o => o.prop('myScopedRoles'));
const roles = focusAtom(root, o => o.prop('roles'));
const isLoaded = focusAtom(root, o => o.prop('isLoaded'));

// --- computed
const isAllowedUser = atom(get => {
  const rolesValue = get(roles);
  const isAllowedRole =
    !!rolesValue && intersection(rolesValue.slice(), LOGIN_ALLOWED_ROLES).length > 0;
  const isAllowedPartnerRole = !!get(partnersAtoms.partners).length;

  return isAllowedRole || isAllowedPartnerRole;
});

const isSuperAdmin = atom(get => {
  const rolesValue = get(roles);
  return !!rolesValue && rolesValue.includes(ROLES.SUPER_ADMIN);
});

const isAdmin = atom(get => {
  const rolesValue = get(roles);
  return get(isSuperAdmin) || (!!rolesValue && rolesValue.includes(ROLES.ADMIN));
});

const hasSuperAdminRole = atom(get => {
  return get(isSuperAdmin);
});

const canEditCurrentPartner = atom(get => {
  return get(hasSuperAdminRole) && !get(partnersAtoms.isReadOnlyModeEnabled);
});

const canViewAccountDeletion = isAdmin;

export const userPermissionsAtoms = {
  root,
  myScopedRoles,
  roles,
  isAllowedUser,
  isSuperAdmin,
  isAdmin,
  hasSuperAdminRole,
  canEditCurrentPartner,
  canViewAccountDeletion,
  isLoaded,
};
