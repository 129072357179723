import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { useAtomValue, useSetAtom, atom } from 'jotai';
import { useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

type NotificationApi = ReturnType<(typeof notification)['useNotification']>[0];
const notificationAtom = atom<NotificationApi | null>(null);

export const useNotificationApi = () => {
  const notificationApi = useAtomValue(notificationAtom);
  if (!notificationApi) {
    throw new Error('Notification API not initialized');
  }

  return notificationApi;
};

// special API for notifications
// to being able to send notifications with intl messages

interface NotificationEventDetails {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fmt: { frmMessage: MessageDescriptor; values?: Record<string, any> };
  notificationProps: Omit<ArgsProps, 'message'>;
  type: 'success' | 'info' | 'warning' | 'error'; // keyof typeof notification;
}

const getSendNotificationByType = (type: NotificationEventDetails['type']) => {
  const res = (
    fmt: NotificationEventDetails['fmt'],
    notificationProps: NotificationEventDetails['notificationProps']
  ) => {
    const ev = new CustomEvent<NotificationEventDetails>('notification', {
      detail: {
        fmt,
        notificationProps,
        type,
      },
    });
    document.dispatchEvent(ev);
  };

  res.top = (
    fmt: NotificationEventDetails['fmt'],
    notificationProps?: NotificationEventDetails['notificationProps']
  ) => res(fmt, { ...notificationProps, placement: 'top' });

  return res;
};

export const intlNotification = {
  success: getSendNotificationByType('success'),
  info: getSendNotificationByType('info'),
  warning: getSendNotificationByType('warning'),
  error: getSendNotificationByType('error'),
};

const useNotificationWithIntl = () => {
  const intl = useIntl();

  useEffect(() => {
    const listener = (event: Event) => {
      // ts is stubborn
      if (event instanceof CustomEvent) {
        const e = event as CustomEvent<NotificationEventDetails>;
        const { fmt, notificationProps, type } = e.detail;
        const message = intl.formatMessage(fmt.frmMessage, fmt.values);

        notification[type]({ ...notificationProps, message } as ArgsProps);
      }
    };

    document.addEventListener('notification', listener);

    return () => {
      document.removeEventListener('notification', listener);
    };
  });
};

export const NotificationApiRoot = () => {
  const [api, notificationContextHolder] = notification.useNotification();

  const setNotificationApi = useSetAtom(notificationAtom);

  useNotificationWithIntl();

  useEffect(() => {
    setNotificationApi(api);

    return () => {
      setNotificationApi(null);
    };
  }, [api, setNotificationApi]);

  return notificationContextHolder;
};
