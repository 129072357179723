import { useSetAtom } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import { State } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { userPermissionsAtoms } from 'state/userPermissions';

export const UserPermissionsAdapter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { userPermissionsStore } = rootStore;

  const { myScopedRoles, roles, state } = userPermissionsStore;

  const serUserPermissionsState = useSetAtom(userPermissionsAtoms.root);

  useEffect(() => {
    serUserPermissionsState({
      myScopedRoles,
      roles,
      isLoaded: state === State.LOADED,
    });
  }, [myScopedRoles, roles, serUserPermissionsState, state]);

  return null;
});
