/* eslint-disable import-x/order */
import { App as AntApp } from 'antd';
import { Provider as JotaiProvider } from 'jotai/index';
import { BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import AntdConfigProvider from 'config/antdConfigProvider';
import { rootStore } from 'config/appConfig';
import { jotaiStore } from 'config/jotaiStore';
import { IntlProvider } from 'context/IntlContext';
import { ReactQueryClientProvider } from 'context/ReactQuery';
import RootStoreContext from 'context/RootStoreContext';
import Routes from 'routes/Routes';
import { MobxToJotaiAdapters } from 'state/mobxAdapters';
import { NotificationApiRoot } from 'state/notification';

import './utils/apm';
import { StyledComponents } from 'config/StyledComponents';

const ManageApp = () => {
  return (
    <IntlProvider>
      <StyledComponents>
        <AntdConfigProvider>
          <AntApp>
            <ErrorBoundary>
              <ReactQueryClientProvider>
                <JotaiProvider store={jotaiStore}>
                  <Router>
                    <RootStoreContext.Provider value={rootStore}>
                      <>
                        <MobxToJotaiAdapters />
                        <FlashMessage />
                        <NotificationApiRoot />
                        <Routes />
                      </>
                    </RootStoreContext.Provider>
                  </Router>
                </JotaiProvider>
              </ReactQueryClientProvider>
            </ErrorBoundary>
          </AntApp>
        </AntdConfigProvider>
      </StyledComponents>
    </IntlProvider>
  );
};

export default ManageApp;
