import { styled } from 'styled-components';

export const LeftMenuDivider = styled.div`
  margin: 8px 1rem -8px;
  opacity: 0.3;

  flex: 0 5 40px;

  align-items: center;

  display: flex;

  &::after {
    content: '';
    width: 100%;
    border-bottom: 1px solid var(--P24-Brand-Primary-brand-colors-Blue-Blue-5);
  }
`;
