import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    manageBlue: '#3A609C',
  },
};

export const StyledComponents = ({ children }: React.PropsWithChildren) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
