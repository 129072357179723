import React from 'react';

import { useToggleMainNavigation } from 'modules/MainNavigation/MainNavigationContext';

/**
 * @deprecated use useToggleMainNavigation directly instead
 */
export const withNavigationCollapseControl = <
  P extends {
    collapseNavigation: () => void;
    // toggleCollapseNavigation: () => void;
  },
>(
  Component: React.ComponentType<P>
) => {
  const WrappedComponent = (props: Omit<P, 'collapseNavigation' | 'toggleCollapseNavigation'>) => {
    const collapseNavigation = useToggleMainNavigation();

    return (
      // @ts-ignore - TS is afraid about props being a different subset of P, but it doesn't matter
      // as long as the required props are present, the component will work
      <Component
        {...props}
        collapseNavigation={collapseNavigation}
        // toggleCollapseNavigation={toggleCollapseNavigation}
      />
    );
  };
  WrappedComponent.displayName = Component.displayName;

  return WrappedComponent;
};
