import { routes } from '@platform24/admin-ui-utils';
import { useIntl } from 'react-intl';

import { useRootStore } from 'context/RootStoreContext';
import { canViewCarePathways } from 'routes/routeGuards';
import { isEducationEnv } from 'utils/appUtils';

import { SubMenuContainer } from '../SubMenuContainer';

const QUESTIONNAIRE_LINK_GROUPS = ['triage', 'diagnosis', 'assessment', 'libraries'];

const useContent24Links = () => {
  const intl = useIntl();
  const { userPermissionsStore, partnersStore } = useRootStore();

  const conditionLinks = QUESTIONNAIRE_LINK_GROUPS.map(itemName => ({
    id: `/questionnaires?category=${itemName}`,
    linkTo: `/questionnaires?category=${itemName}`,
    label: intl.formatMessage({ id: `condition-list.category-${itemName}` }),
    testId: `main-navigation-content24-${itemName}`,
  }));

  const selfcareLink = {
    id: routes.content24.selfCare.list.path,
    linkTo: routes.content24.selfCare.list.path,
    label: intl.formatMessage({ id: 'selfcare.menu-item' }),
    testId: 'main-navigation-content24-selfcare',
  };
  const informationArticlesLink = {
    id: routes.content24.articles.list.path,
    linkTo: routes.content24.articles.list.path,
    label: intl.formatMessage({ id: 'articles.title' }),
    testId: 'main-navigation-content24-articles',
  };
  const statisticsLink = {
    id: '/statistics',
    linkTo: '/statistics',
    label: intl.formatMessage({ id: 'statistics.header' }),
    testId: 'main-navigation-content24-statistics',
  };
  const testLink = {
    id: '/test',
    linkTo: '/test',
    label: intl.formatMessage({ id: 'test.menu-item' }),
    testId: 'main-navigation-content24-test',
  };

  const list = [...conditionLinks, selfcareLink].sort((a, b) => a.label.localeCompare(b.label));

  if (canViewCarePathways(userPermissionsStore) && !isEducationEnv()) {
    list.push(informationArticlesLink);
  }

  if (partnersStore.partnerCustomizations.get('CODE24_STATISTICS_ENABLED')) {
    list.push(statisticsLink);
  }

  return [...list, testLink];
};

export const Content24Submenu = () => {
  const links = useContent24Links();
  return (
    <SubMenuContainer rootLink="/content24">
      {links.map(link => (
        <SubMenuContainer.Item
          key={link.id}
          linkTo={link.linkTo}
          label={link.label}
          data-testid={link.testId}
        />
      ))}
    </SubMenuContainer>
  );
};
