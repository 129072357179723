import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { CareUnit } from 'api/careUnitsApi';
import withOnUnloadGuard, { OnUnloadGuardProps } from 'components/HOC/withOnUnloadGuard';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import PageWithSectionsHeader from 'components/PageWithSectionsHeader';
import RootStoreContext from 'context/RootStoreContext';
import { notification } from 'services/NotificationService';
import { parseFloatStringToNumber } from 'utils/appUtils';

import styles from './AddCareUnit.module.css';
import CareUnitDetailsForm from '../components/CareUnitsDetailsForm';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string }>,
    OnUnloadGuardProps {}

@observer
class AddCareUnit extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  get breadcrumbs() {
    const {
      match: {
        params: { careProviderId },
      },
    } = this.props;

    return [
      {
        iconName: 'home',
        text: <FormattedMessage id="care-units.all-care-units" />,
        link: '/health-care',
      },
      {
        text: careProviderId,
        link: `/health-care/${careProviderId}/care-units`,
      },
      {
        text: <FormattedMessage id="care-units.new-care-unit" />,
      },
    ];
  }

  componentDidMount() {
    this.props.updateIsDirty(true);
  }

  componentWillUnmount() {
    this.context.careUnitStore.dispose();
  }

  onSubmit = async (data: CareUnit) => {
    try {
      const {
        match: {
          params: { careProviderId },
        },
        intl,
        history,
        updateIsDirty,
      } = this.props;
      const { careUnitStore } = this.context;
      const cleanedData = {
        ...data,
        longitude: parseFloatStringToNumber(data.longitude),
        latitude: parseFloatStringToNumber(data.latitude),
      };

      await careUnitStore.createCareUnit(careProviderId, cleanedData);

      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'care-providers-list.care-unit-added' }),
      });

      updateIsDirty(false);

      if (careUnitStore.careUnit) {
        history.push(
          `/health-care/${careProviderId}/care-units/${careUnitStore.careUnit.id}/basic-details`
        );
      } else {
        // TODO: this should not happen, but find a way to better manage this scenario
        history.push(`/health-care/${careProviderId}/care-units`);
      }
      /* eslint-disable no-empty */
    } catch (e: any) {}
  };

  onCancel = () => {
    const { history, match, updateIsDirty } = this.props;
    updateIsDirty(false);
    history.push(`/health-care/${match.params.careProviderId}/care-units`);
  };

  render() {
    const { careUnitStore } = this.context;

    return (
      <Fragment>
        <PageWithSectionsHeader
          title={<FormattedMessage id="care-units.new-care-unit" />}
          breadcrumbs={this.breadcrumbs}
        />
        <PageWithSectionsContent className={styles.contentWrapper}>
          <CareUnitDetailsForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            isSaving={careUnitStore.isSaving()}
          />
        </PageWithSectionsContent>
      </Fragment>
    );
  }
}

export default withOnUnloadGuard(injectIntl(AddCareUnit));
