import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useRootStore } from 'context/RootStoreContext';
import { partnersAtoms } from 'state/partners';

export const useOrganizationSelector = () => {
  const { authStore } = useRootStore();
  const partners = useAtomValue(partnersAtoms.partners);
  const currentPartner = useAtomValue(partnersAtoms.currentPartner);
  const history = useHistory();

  const onChange = useCallback(
    async (partnerId: string) => {
      await authStore.partnerSwitch(partnerId);
      // Reset the app with new partner context
      history.push('/');
      window.location.reload();
    },
    [authStore, history]
  );

  return {
    partners,
    currentPartnerId: currentPartner?.id,
    onChange,
  };
};
