import axios from 'axios';

import { LANGS } from 'constants/enums';
import { IDENTITIES } from 'constants/practitioner';
import { ROLES } from 'constants/roles';

import { CareUnit } from './careUnitsApi';
import { Origin } from './userApi';

export interface ExternalId {
  externalId: string;
  externalIdType: IDENTITIES;
}

export interface ExtendedRole {
  id: string;
  practitionerId: string | null;
  careUnit: CareUnit;
  role: ROLES;
  isPrimaryRole: boolean;
  lockedFromAutoManagement?: boolean;
}

// NB. for new Roles implementation
export interface CreatePractionerRoleIT {
  role: ROLES;
  isPrimaryRole: boolean;
  lockedFromAutoManagement?: boolean;
  careUnitIds: string[];
  capabilities?: string[];
}

export interface EditPractitionerRoleIT {
  role: ROLES;
  isPrimaryRole: boolean;
  lockedFromAutoManagement?: boolean;
  careUnitId: string;
}

export interface PractitionerCareUnit extends CareUnit {
  regionId: string;
  careProviderName: string;
  careProviderId: string;
  marketCountryCode: string;
  marketLanguageCode: LANGS;
  careProvider: {
    externalOrgId: string;
    externalOrgIdType: IDENTITIES;
    id: string;
    name: string;
  };
  adminEmail: string | null;
  email: string | null;
  regionIdType: string | null;
}

export interface Practitioner {
  id: string;
  externalId: string;
  externalIdType: IDENTITIES;
  givenName: string;
  middleAndSurname: string;
  title: string;
  email: string | null;
  mobileNumber: string | null;
  roles: ROLES[];
  extendedRoles: ExtendedRole[];
  careUnit: PractitionerCareUnit | null;
  origins: Origin[];
  defaultOriginId: string;
  externalIds: ExternalId[];
  activated?: boolean;
  createdAt?: string;
}

export const PRACTITIONER_URL = '/rest/admin/v1/practitioners';
export const PRACTITIONER_V2_URL = '/rest/admin/v2/practitioners';
export const SEARCH_PRACTITIONER_URL = `${PRACTITIONER_URL}/search-by-external-id`;

export const fetchPractitioners = () => axios.get<Practitioner[]>(PRACTITIONER_URL);

export const fetchPractitionerByExternalId = ({ externalId, externalIdType }: ExternalId) => {
  const IDtypeKey = externalIdType === IDENTITIES.INTERNAL_IDP ? 'email' : 'externalId';

  return axios.post<Practitioner[]>(SEARCH_PRACTITIONER_URL, {
    [IDtypeKey]: externalId,
    externalIdType,
  });
};

export const fetchPractitioner = async (practitionerId: string) => {
  const response = await axios.get<Practitioner>(`${PRACTITIONER_V2_URL}/${practitionerId}`);

  return response;
};

export const resetPractitionerPassword = async (practitionerId: string) => {
  const response = await axios.post<Practitioner>(
    `${PRACTITIONER_V2_URL}/${practitionerId}/reset-password`
  );

  return response;
};

export const resendPractitionerInvite = async (practitionerId: string) => {
  const response = await axios.post<Practitioner>(
    `${PRACTITIONER_V2_URL}/${practitionerId}/resend-invite`
  );

  return response;
};

export const updatePractitioner = (practitioner: Practitioner) =>
  // PUT endpoint is incompatible with the result of GET method in the sense that
  // primary external id has to be excluded from externalIds array. If it's not, it
  // might have unexpected results. This should be fixed on the backend: https://platform24.atlassian.net/browse/AX-9421
  axios.put<Practitioner>(`${PRACTITIONER_URL}/${encodeURIComponent(practitioner.id)}`, {
    ...practitioner,
    externalIds: practitioner.externalIds.slice(1),
  });

export const createPractitioner = (practitioner: Practitioner) =>
  // POST endpoint is incompatible with the result of GET method in the sense that
  // primary external id has to be excluded from externalIds array. If it's not, it will be
  // duplicated. This should be fixed on the backend: https://platform24.atlassian.net/browse/AX-9421
  axios.post<Practitioner>(PRACTITIONER_URL, {
    ...practitioner,
    externalIds: practitioner.externalIds.slice(1),
  });

export const createPractitionerRole = async (
  practitionerId: string,
  roles: CreatePractionerRoleIT
) => {
  const response = await axios.post<Practitioner>(
    `${PRACTITIONER_URL}/${encodeURIComponent(practitionerId)}/care-unit-roles`,
    roles
  );
  return response;
};

export const createPractitionerRoleV2 = async (
  practitionerId: string,
  roles: CreatePractionerRoleIT
) => {
  const response = await axios.post<Practitioner>(
    `${PRACTITIONER_V2_URL}/${encodeURIComponent(practitionerId)}/care-unit-roles`,
    roles
  );
  return response;
};

// NB. new endpoint allows only one role edit at a time
// 1. Frontend does not care about how isPrimaryRole is implemented since it's handled by backend
export const editPractitionerRole = async (
  practitionerId: string,
  roleId: string,
  roles: EditPractitionerRoleIT
) => {
  const response = await axios.patch<Practitioner>(
    `${PRACTITIONER_URL}/${encodeURIComponent(practitionerId)}/care-unit-roles/${roleId}`,
    roles
  );

  return response;
};

export const editPractitionerRoleV2 = async (
  practitionerId: string,
  roleId: string,
  roles: EditPractitionerRoleIT,
  roleCareUnitId: string
) => {
  const response = await axios.patch<Practitioner>(
    `${PRACTITIONER_V2_URL}/${encodeURIComponent(
      practitionerId
    )}/care-unit-roles/${roleId}?careUnitId=${roleCareUnitId}`,
    roles,
    {
      ignoreErrorStatuses: [400],
    }
  );
  return response;
};

export const archivePractitionerRole = async (practitionerId: string, roleId: string) => {
  const response = await axios.delete<Practitioner>(
    `${PRACTITIONER_URL}/${encodeURIComponent(practitionerId)}/care-unit-roles/${roleId}`
  );
  return response;
};

export const archivePractitionerRoleV2 = async (
  practitionerId: string,
  roleId: string,
  roleCareUnitId: string
) => {
  const response = await axios.delete<Practitioner>(
    `${PRACTITIONER_V2_URL}/${encodeURIComponent(
      practitionerId
    )}/care-unit-roles/${roleId}?careUnitId=${roleCareUnitId}`,
    {
      ignoreErrorStatuses: [400],
    }
  );
  return response;
};
