import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { partnerScopeCustomizationAtom } from './partnerScopeCustomizationAtom';
import { ParnterScopeCustomization } from '../types';

export const usePartnerScopeCustomization = (partnerId: string) => {
  const [, setPartnerScopeCustomization] = useAtom(partnerScopeCustomizationAtom);

  const { error, data, isLoading, isSuccess } = useQuery({
    queryKey: ['partnerScopeCustomization'],
    queryFn: () =>
      partnerId
        ? axios
            .get(`/rest/admin/v2/partners/${partnerId}/customizations/`)
            .then(({ data }: { data: ParnterScopeCustomization[] }) => {
              const customizations: Record<string, unknown> = {};
              for (const customization of data) {
                customizations[customization.key] = customization.value;
              }
              return customizations;
            })
        : Promise.resolve({}),
  });

  useEffect(() => {
    if (isSuccess && data) {
      setPartnerScopeCustomization(data);
    }
  }, [isSuccess, data, setPartnerScopeCustomization]);

  return {
    data,
    error,
    isLoading,
    isSuccess,
  };
};
