import axios from 'axios';

import { LANGS } from 'constants/enums';

import { PARTNERS_URI, ChildOrigin } from './partnersApi';

export interface Hostname {
  hostname: string;
  primary: boolean;
}

export interface Alias {
  alias: string;
  primary: boolean;
}

export interface Origin {
  id: string;
  name: string;
  clinicName: string | null;
  patientUrl: string | null;
  urlSuffixes?: string[] | null;
  email?: string | null;
  bankIdDisplayName?: string | null;
  smsDisplayName?: string | null;
  paymentMethod?: string | null;
  originGroup: string;
  payor: string | null;
  defaultLanguage: LANGS;
  parentOriginId?: string;
  countryCode: string;
  aliases: Alias[];
  hostnames: Hostname[];
  origins?: ChildOrigin[];
}

export interface OriginSave {
  id: string;
  name: string;
  clinicName?: string | null;
  email?: string | null;
  bankIdDisplayName?: string | null;
  smsDisplayName?: string | null;
  paymentMethod?: string | null;
  urlSuffixes?: string[] | null;
  countryCode: string;
  aliases?: Alias[];
  hostnames?: Hostname[];
  parentOriginId?: string;
}

export interface OriginCareUnit {
  careUnitId: string;
  name: string;
  enabled: boolean;
}

const getAdminBaseUri = (partnerId: string) => `${PARTNERS_URI}/${partnerId}/origins`;

export const fetchOrigin = (partnerId: string, originId: string) =>
  axios.get<Origin>(`${getAdminBaseUri(partnerId)}/${originId}`);

export const updateOrigin = (partnerId: string, originId: string, data: OriginSave) =>
  axios.put<Origin>(`${getAdminBaseUri(partnerId)}/${originId}`, data, {
    ignoreErrorStatuses: [400],
  });

export const createOrigin = (partnerId: string, data: OriginSave) =>
  axios.post<Origin>(getAdminBaseUri(partnerId), data, {
    ignoreErrorStatuses: [400],
  });

export const fetchOriginCareUnits = (originId: string, partnerId: string) =>
  axios.get<OriginCareUnit[]>(`${getAdminBaseUri(partnerId)}/${originId}/careunits`);

export const updateOriginCareUnits = (
  data: OriginCareUnit[],
  originId: string,
  partnerId: string
) => axios.put(`${getAdminBaseUri(partnerId)}/${originId}/careunits`, data);
