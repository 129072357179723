import { atom } from 'jotai';

interface Customizations {
  [key: string]: unknown;
}

export const partnerScopeCustomizationAtom = atom<Customizations>({});

export const getCustomizationAtom = <T = unknown>(key: string) =>
  atom(get => get(partnerScopeCustomizationAtom)?.[key] as T | undefined);
