import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';

export const isMainNavigationCollapsedAtom = atomWithStorage('isNavigationCollapsed', false);

export const useToggleMainNavigation = () => {
  const setIsCollapsed = useSetAtom(isMainNavigationCollapsedAtom);

  const toggleMainNavigation = useCallback(
    (value?: boolean) => {
      setIsCollapsed(isCollapsed => value ?? !isCollapsed);
    },
    [setIsCollapsed]
  );

  return toggleMainNavigation;
};

export const useIsMainNavigationCollapsed = () => {
  return useAtomValue(isMainNavigationCollapsedAtom);
};
