import { createContext, useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import { styled } from 'styled-components';

import { useIsMainNavigationCollapsed } from '../MainNavigationContext';

const SubmenuWrap = styled.div<{ isMainMenuCollapsed: boolean }>`
  position: fixed;
  transform: translateX(${props => (props.isMainMenuCollapsed ? 80 : 232)}px);
  transition: transform 0.3s ease;
  min-width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--Surface-Blue);
  z-index: 243;
  padding-top: 1rem;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

const SubmenuItemWrap = styled.div`
  padding: 0 0.8rem;
  margin: 0 0.2rem;
  border-radius: 0.4rem;
  height: ${40 / 16}rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: color-mix(in srgb, var(--Surface-Blue) 90%, black 10%);
    color: var(--color-teal);
  }
`;

const SubmenuContext = createContext<{ rootLink?: string }>({});

export const SubMenuContainer = ({
  children,
  rootLink,
}: React.PropsWithChildren<{ rootLink?: string }>) => {
  const isCollapsed = useIsMainNavigationCollapsed();
  return (
    <SubmenuContext.Provider value={{ rootLink }}>
      {createPortal(
        <SubmenuWrap data-testid="main-navigation-submenu" isMainMenuCollapsed={isCollapsed}>
          {children}
        </SubmenuWrap>,
        document.body
      )}
    </SubmenuContext.Provider>
  );
};

const SubMenuItem = ({ label, linkTo }: { label: string | React.ReactNode; linkTo: string }) => {
  const { rootLink } = useContext(SubmenuContext);
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push((rootLink ?? '') + linkTo);
  }, [history, rootLink, linkTo]);
  return (
    <SubmenuItemWrap onClick={onClick} role="link">
      {label}
    </SubmenuItemWrap>
  );
};
SubMenuItem.displayName = 'SubMenuItem';

SubMenuContainer.Item = SubMenuItem;
