import { Layout } from 'antd';
import classNames from 'classnames';
import { styled } from 'styled-components';

import { BottomMenu } from './components/BottomMenu';
import { LeftMenuDivider } from './components/LeftMenuDivider';
import { MainMenuLogo } from './components/MainMenuLogo';
import { MainMenuPages } from './components/MainMenuPages';
import { Organization } from './components/Organization/Organization';
import styles from './MainNavigation.module.css';
import { useIsMainNavigationCollapsed } from './MainNavigationContext';

export const MainNavigation = () => {
  const isCollapsed = useIsMainNavigationCollapsed();

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      theme="light"
      width="232"
      className={classNames(styles.container, { [styles.isCollapsed]: isCollapsed })}
    >
      <div className={styles.sliderChildrenWrapper}>
        <MainMenuLogo />

        <LittleSpacer />

        <Organization />

        <LeftMenuDivider />

        <MainMenuPages />

        <BottomMenu />
      </div>
    </Layout.Sider>
  );
};

const LittleSpacer = styled.div`
  // logo has "shrink" 1 which means that logo shrings much less
  // than this space which has "shrink" 5
  flex: 0 5 40px;
`;
